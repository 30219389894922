import React, {PureComponent} from 'react'
import styles from './Navigation.module.scss'
import {Link, StaticQuery, graphql} from 'gatsby'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {CURSORS, ROUTES} from '../../Config'
import {AppConsumer} from '../../AppProvider'

class Navigation extends PureComponent {

    constructor(props) {
        super(props)
        this.toggleNav = this.toggleNav.bind(this)
        this.showNavCursor = this.showNavCursor.bind(this)
        this.showHeaderCursor = this.showHeaderCursor.bind(this)
        this.showToggleCursor = this.showToggleCursor.bind(this)
        this.hideCursor = this.hideCursor.bind(this)

        this.state = {
            isNavVisible: false,
        }
    }

    toggleNav() {
        this.props.showCursor(this.state.isNavVisible ? CURSORS.PLUS : CURSORS.CLOSE)
        this.setState(() => ({isNavVisible: !this.state.isNavVisible}))
    }

    showHeaderCursor() {
        this.props.showCursor(CURSORS.PLUS)
    }

    showToggleCursor() {
        this.props.showCursor(this.state.isNavVisible ? CURSORS.CLOSE : CURSORS.PLUS)
    }

    showNavCursor() {
        this.props.showCursor(this.props.inverted ? CURSORS.ARROW_RIGHT_WHITE : CURSORS.ARROW_RIGHT_BLACK)
    }

    hideCursor() {
        this.props.hideCursor()
    }

    render() {
        const {isNavVisible} = this.state
        const {locale, lang, alternatePath, alternateLocale, inverted, showLogo, data} = this.props
        const navigationData = data.navigation.edges.find(edge => edge.node.lang === lang)
        const {menu_button_text, close_button_text, language_button_text} = navigationData ? navigationData.node.data : {}

        return (
            <div className={cx(styles.Main, {[styles.Inverted]: inverted}, {[styles.IsOpen]: isNavVisible})}>
                <nav
                    className={cx(styles.Nav, {[styles.IsVisible]: isNavVisible})}
                    onClick={this.toggleNav}>
                    <div className={styles.Group}>
                        {
                            ROUTES.map((route, index) => {
                                const delay = index * 50
                                const routeData = data[route].edges.find(edge => edge.node.lang === lang)
                                const name = routeData ? routeData.node.data.headline.text : ''

                                return (
                                    <div
                                      key={route}
                                      className={styles.Item}
                                      onMouseEnter={this.showNavCursor}
                                      onMouseLeave={this.hideCursor}>
                                        <div
                                            className={styles.Inner}
                                            style={{animationDelay: `${delay}ms`}}>
                                            <Link
                                                to={`/${locale}/${route}`}
                                                className={styles.Link}
                                                style={{animationDelay: `${delay + 50}ms`}}>
                                                {name}
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </nav>
                <header className={styles.Header}>
                    {(alternatePath && alternateLocale) &&
                    <Link
                      to={alternatePath}
                      className={cx(styles.HeaderItem, { [styles.IsHidden]: !isNavVisible })}
                      onMouseEnter={this.showHeaderCursor}
                      onMouseLeave={this.hideCursor}
                      onClick={this.hideCursor}>
                        {language_button_text}
                    </Link>
                    }
                    <button
                      className={cx(styles.HeaderItem, { [styles.Close]: isNavVisible })}
                      onMouseEnter={this.showToggleCursor}
                      onMouseLeave={this.hideCursor}
                      onClick={this.toggleNav}
                    >
                        {isNavVisible ? close_button_text : menu_button_text}
                    </button>
                    {showLogo &&
                    <Link className={styles.Logo}
                          to={`${locale}/`}
                          onMouseEnter={this.showHeaderCursor}
                          onMouseLeave={this.hideCursor}
                          onClick={this.hideCursor}>
                        Aquilas
                    </Link>
                    }
                </header>
            </div>
        )
    }
}

Navigation.propTypes = {
    locale: PropTypes.string.isRequired,
    alternatePath: PropTypes.string,
    alternateLocale: PropTypes.string,
    inverted: PropTypes.bool,
    showLogo: PropTypes.bool,
}

Navigation.defaultProps = {
    inverted: false,
    showLogo: true,
}

export default props => (
    <StaticQuery
        query={graphql`
      query navigationQuery {
        about: allPrismicAbout {
          edges {
            node {
              lang
              data {
                headline {
                  text
                }
              }
            }
          }
        }
        projects: allPrismicProjects {
          edges {
            node {
              lang
              data {
                headline {
                  text
                }
              }
            }
          }
        }
        team: allPrismicTeam {
          edges {
            node {
              lang
              data {
                headline {
                  text
                }
              }
            }
          }
        }
        career: allPrismicCareer {
          edges {
            node {
              lang
              data {
                headline {
                  text
                }
              }
            }
          }
        }
        investors: allPrismicInvestors {
          edges {
            node {
              lang
              data {
                headline {
                  text
                }
              }
            }
          }
        }
        contact: allPrismicContact {
          edges {
            node {
              lang
              data {
                headline {
                  text
                }
              }
            }
          }
        }
        navigation: allPrismicNavigation {
          edges {
            node {
              lang
              data {
                menu_button_text
                close_button_text
                language_button_text
              }
            }
          }
        }
      }
    `}
        render={data => (
            <AppConsumer>
                {({showCursor, hideCursor}) => <Navigation {...props} data={data} showCursor={showCursor} hideCursor={hideCursor}/>}
            </AppConsumer>
        )}
    />
)