export const BASE_FONT_SIZE = 16

export const MQ_PHONE = 375
export const MQ_TABLET = 768
export const MQ_DESKTOP_SMALL = 1024
export const MQ_DESKTOP_MEDIUM = 1440
export const MQ_DESKTOP_LARGE = 1920
export const MQ_DESKTOP_EXTRALARGE = 2560

export const ROUTES = [
    'about',
    'projects',
    'team',
    'career',
    'investors',
    'contact',
]

export const TEAM_ITEMS_PER_ROW = {
    [MQ_PHONE]: 2,
    [MQ_TABLET]: 4,
    [MQ_DESKTOP_MEDIUM]: 5
}

export const CURSORS = {
    ARROW_DOWN: 0,
    ARROW_LEFT_BLACK: 1,
    ARROW_LEFT_RED: 2,
    ARROW_LEFT_WHITE: 3,
    ARROW_RIGHT_BLACK: 4,
    ARROW_RIGHT_RED: 5,
    ARROW_RIGHT_WHITE: 6,
    ARROW_UP: 7,
    CLOSE: 8,
    PLUS: 9,
}
