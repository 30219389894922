import React from 'react'
import styles from './GridDebug.module.scss'

class GridDebug extends React.Component {

    render() {
        const columns = Array(16).fill(null)
        const visible = typeof window !== 'undefined' && window.location.hash.includes('grid')

        if (visible) {
            return (
                <div className={styles.Main}>
                    {columns.map((item, index) => (
                        <div className={styles.Item} key={index}>
                            <div className={styles.Inner}/>
                        </div>
                    ))}
                </div>
            )
        }
        return null
    }
}

export default GridDebug
