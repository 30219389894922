import React from 'react'
import '../../fonts/Fonts.scss'
import '../../fonts/StylesSets.scss'
import './Global.scss'
import styles from './Layout.module.scss'
import Footer from '../footer/Footer'
import Navigation from '../navigation/Navigation'
import PropTypes from 'prop-types'
import GridDebug from '../gridDebug/GridDebug'
import Cursor from '../cursor/Cursor'

const Layout = ({children, locale, lang, alternatePath, alternateLocale, hasFooter, inverted, showLogo}) => (
    <div className={styles.Main}>
        <Navigation
            locale={locale}
            lang={lang}
            alternatePath={alternatePath}
            alternateLocale={alternateLocale}
            inverted={inverted}
            showLogo={showLogo}
        />
        {children &&
        <div className={styles.Content}>
            {children}
        </div>
        }
        {hasFooter && <Footer locale={locale}/>}
        <Cursor/>
        <GridDebug/>
    </div>
)

Layout.propTypes = {
    locale: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    alternatePath: PropTypes.string,
    hasFooter: PropTypes.bool,
    inverted: PropTypes.bool,
    showLogo: PropTypes.bool,
}

Layout.defaultProps = {
    hasFooter: true,
    inverted: false,
    showLogo: true,
}

export default Layout