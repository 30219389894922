import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'
import styles from './Footer.module.scss'

const Footer = (props) => {
    const {linkedin_profile, instagram_profile, copyright} = props.data.prismicFooter.data
    const {locale} = props

    return (
        <footer className={styles.Main}>
            <div className={styles.Inner}>
                <div className={styles.Item}>{copyright}</div>
                <div className={styles.Links}>
                    <Link className={styles.Item} to={`/${locale}/legal`}>Impressum</Link>
                    <div className={styles.Item} dangerouslySetInnerHTML={{__html: linkedin_profile.html}}/>
                    <div className={styles.Item} dangerouslySetInnerHTML={{__html: instagram_profile.html}}/>
                </div>
            </div>
        </footer>
    )
}

export default props => (
    <StaticQuery
        query={
            graphql`
            query footerQuery {
              prismicFooter {
                    data {
                      linkedin_profile {
                      html
                      }
                      instagram_profile {
                      html
                      }
                      copyright
                    }
                  }
            }
        `}
        render={data => <Footer data={data} {...props}/>}
    />
)