import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styles from './Cursor.module.scss'
import {AppConsumer} from '../../AppProvider'
import cx from 'classnames'
import {CURSORS} from '../../Config'
import detectIe from 'detectie'

class Cursor extends PureComponent {

    constructor(props) {
        super(props)
        this.x = 0
        this.y = 0
        this.target = null
        this.needsUpdate = false
        this.update = this.update.bind(this)
        this.onMove = this.onMove.bind(this)
        this.state = {hasMouse: false}
    }

    componentDidMount() {
        const hasMouse = window.matchMedia('(pointer:fine)').matches
        if (hasMouse || detectIe()) {
            this.raf = requestAnimationFrame(this.update)
            window.addEventListener('mousemove', this.onMove)
            this.setState({hasMouse: true})
        }
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.raf)
        window.removeEventListener('mousemove', this.onMove)
    }

    onMove(event) {
        this.x = event.clientX
        this.y = event.clientY
        this.needsUpdate = true
    }

    update() {
        this.raf = requestAnimationFrame(this.update)

        if (this.needsUpdate) {
            this.needsUpdate = false
            this.node.style.transform = `translate(${this.x}px,${this.y}px)`
        }
    }

    render() {
        if (!this.state.hasMouse) return null

        return (
            <AppConsumer>
                {({isCursorVisible, cursorType}) => {
                    const classes = cx(
                        styles.Main,
                        {[styles.IsVisible]: isCursorVisible},
                        {[styles.ArrowUp]: cursorType === CURSORS.ARROW_UP},
                        {[styles.ArrowDown]: cursorType === CURSORS.ARROW_DOWN},
                        {[styles.ArrowLeftBlack]: cursorType === CURSORS.ARROW_LEFT_BLACK},
                        {[styles.ArrowLeftRed]: cursorType === CURSORS.ARROW_LEFT_RED},
                        {[styles.ArrowLeftWhite]: cursorType === CURSORS.ARROW_LEFT_WHITE},
                        {[styles.ArrowRightBlack]: cursorType === CURSORS.ARROW_RIGHT_BLACK},
                        {[styles.ArrowRightRed]: cursorType === CURSORS.ARROW_RIGHT_RED},
                        {[styles.ArrowRightWhite]: cursorType === CURSORS.ARROW_RIGHT_WHITE},
                        {[styles.Close]: cursorType === CURSORS.CLOSE},
                        {[styles.Plus]: cursorType === CURSORS.PLUS},
                    )

                    return (
                        <div className={classes} ref={node => this.node = node}>
                            {isCursorVisible}
                        </div>
                    )
                }}
            </AppConsumer>
        )
    }
}

export default Cursor

Cursor.propTypes = {
    type: PropTypes.string
}